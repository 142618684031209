<nb-card [nbSpinner]="isLoading">
  <div class="update-password">
    <h2>Defina sua senha</h2>
    <div class="update-password--content">
      <div class="update-password--content--form" [formGroup]="formGroup">

        <div class="col-12 col-md-12 mt-3">
          <label for="password">Nova senha</label>
          <div class="update-password--content--form--input">
            <input id="password"
              autocomplete="off"
              formControlName="password"
              nbInput shape="rectangle" fullWidth
              placeholder="*********"
              maxlength="32"
              (ngModelChange)="onPasswordChange()"
              [type]="hidePassword ? 'password' : 'text'">
            <button type="button" nbSuffix nbButton ghost (click)="hidePassword = !hidePassword">
              <nb-icon [icon]="!hidePassword ? 'eye-outline' : 'eye-off-2-outline'"
                pack="eva">
              </nb-icon>
            </button>
          </div>
        </div>

        <div class="col-12 col-md-12 mt-3">
          <label for="confirmPassword">Confirmação de senha</label>
          <div class="update-password--content--form--input">
            <input id="confirmPassword"
              autocomplete="off"
              formControlName="confirmPassword"
              nbInput shape="rectangle" fullWidth
              placeholder="*********"
              maxlength="32"
              (ngModelChange)="onPasswordChange()"
              [type]="hideConfirmPassword ? 'password' : 'text'">
            <button type="button" nbSuffix nbButton ghost (click)="hideConfirmPassword = !hideConfirmPassword">
              <nb-icon [icon]="!hideConfirmPassword ? 'eye-outline' : 'eye-off-2-outline'"
                pack="eva">
              </nb-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <p>Sua senha deve conter, ao menos: <br /> 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caracter especial</p>

    <div class="col d-flex justify-content-end flex-sm-grow-0 mt-5">
      <button nbButton
        status="success"
        [disabled]="!isPasswordOk"
        outline
        (click)="onSubmit()"
        type="button">Salvar
      </button>
    </div>
  </div>
</nb-card>