//#region Imports

import { Component, OnInit } from '@angular/core';
import { SeoService } from './@core/utils';
import { UserService } from './services/user/user.service';
import { UpdatePasswordComponent } from './modals/update-password/update-password.component';
import { NbDialogService } from '@nebular/theme';

//#endregion

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  //#region Constructor

  constructor(
    private seoService: SeoService,
    private readonly userService: UserService,
    private readonly dialog: NbDialogService,
  ) { }

  //#endregion

  //#region LifeCycle Events

  public async ngOnInit(): Promise<void> {
    this.seoService.trackCanonicalChanges();

    const currentUser = this.userService.getCurrentUser();

    if (currentUser && currentUser?.shouldRequestPasswordChange)
      setTimeout(() => {
        this.dialog.open(UpdatePasswordComponent, {
          closeOnEsc: false,
          closeOnBackdropClick: false,
        });
      }, 500);
  }

  //#endregion

}
