export const apiRoutes = {
  auth: {
    login: '/auth/local',
  },
  companies: {
    list: '/companies',
    byId: '/companies/{id}',
    create: '/companies',
  },
  medias: '/medias/presigned',
  places: {
    list: '/places',
    byId: '/places/{id}',
  },
  serviceOrders: {
    list: '/services/orders',
    byId: '/services/orders/{id}',
    cancel: '/services/orders/{id}/cancel',
  },
  users: {
    list: '/users',
    get: '/users/{userId}',
    create: '/users',
    update: '/users/{userId}',
    me: '/users/me',
    password: '/users/password/{id}',
  },
  userPassword: {
    forgotPassword: '/users/password/forgot/email/{email}',
    resetPassword: '/users/password/reset/{resetPasswordCode}',
  },
} as const;
