//#region Imports

import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';
import { getCrudErrors } from '../../shared/utils/functions';
import { Subscription } from 'rxjs';
import { UserProxy } from '../../models/proxys/user.proxy';
import { apiRoutes } from '../../../environments/api-routes';
import { UserService } from '../../services/user/user.service';

//#endregion

@Component({
  selector: 'ngx-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnDestroy {

  //#region Constructor

  constructor(
    protected formBuilder: FormBuilder,
    private readonly dialogRef: NbDialogRef<UpdatePasswordComponent>,
    private readonly http: HttpAsyncService,
    private readonly toast: NbToastrService,
    private readonly userService: UserService,
  ) {
    this.formGroup = formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  //#endregion

  //#region Properties

  public isLoading: boolean = false;

  public hidePassword: boolean = true;
  public hideConfirmPassword: boolean = true;

  public isPasswordOk: boolean = false;

  public formGroup: FormGroup;

  private passwordSubscription: Subscription = new Subscription();
  private confirmPasswordSubscription: Subscription = new Subscription();

  //#endregion

  //#region LifeCycle Events

  public ngOnDestroy(): void {
    this.passwordSubscription.unsubscribe();
    this.confirmPasswordSubscription.unsubscribe();
  }

  //#endregion

  //#region Methods

  public async onSubmit(): Promise<void> {
    if (this.isLoading)
      return;

    const payload = this.formGroup.getRawValue();

    if (payload.password !== payload.confirmPassword)
      return void this.toast.danger('As senhas não conferem...', 'Oops...');

    const currentUser = this.userService.getCurrentUser();

    this.isLoading = true;

    const { error } = await this.http.put<UserProxy>(
      apiRoutes.users.password.replace('{id}', currentUser?.id ? currentUser?.id.toString() : '0'),
      { newPassword: payload.password },
    );

    if (error)
      return void this.toast.danger(getCrudErrors(error)[0], 'Oops...');

    this.toast.success('Senha atualizada com sucesso!', 'Sucesso');
    localStorage.setItem(environment.keys.user, JSON.stringify({ ...currentUser, shouldRequestPasswordChange: false }));

    this.isLoading = false;
    this.dialogRef.close();
  }

  public async onPasswordChange(): Promise<void> {
    const password: string = this.formGroup.get('password')?.value;
    const confirmPassword: string = this.formGroup.get('confirmPassword')?.value;

    const pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}/g

    this.isPasswordOk = !!password.match(pattern) && password === confirmPassword;
  }

  //#endregion

}
