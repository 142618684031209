import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbInputModule, NbSelectModule, NbSpinnerModule } from '@nebular/theme';
import { UpdatePasswordComponent } from './update-password.component';

@NgModule({
  imports: [
    CommonModule,
    NbButtonModule,
    RouterModule,
    NbSpinnerModule,
    NbInputModule,
    NbSelectModule,
    FormsModule,
    NbIconModule,
    ReactiveFormsModule,
    NbCardModule,
    NbFormFieldModule,
  ],
  exports: [
    UpdatePasswordComponent,
  ],
  declarations: [
    UpdatePasswordComponent,
  ],
})
export class UpdatePasswordModule { }
