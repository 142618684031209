export enum RolesEnum {
  ADMIN = 'admin',
  MAIN_ADMIN = 'main_admin',
  REQUESTER = 'requester',
  TRANSFER = 'transfer',
}

export const roleToString: Record<RolesEnum, string> = {
  [RolesEnum.ADMIN]: 'Administrador',
  [RolesEnum.MAIN_ADMIN]: 'Administrador Geral',
  [RolesEnum.REQUESTER]: 'Requisitante',
  [RolesEnum.TRANSFER]: 'Movimentador',
};
